




































import { Component, Prop, Vue } from 'vue-property-decorator';
import Company from '@/models/Company';
import Billing from '@/models/Billing';
import BillingRepository from '@/api/repositories/BillingRepository';
import { RepositoryFactory } from '@/api/RepositoryFactory';

const billingRepository: BillingRepository = RepositoryFactory.get('billing');

@Component({
    components: {}
})
export default class CompanyTableComponent extends Vue {
    @Prop({ default: () => [] })
    public billings!: Billing[];

    @Prop({ default: '' })
    public from!: string;

    @Prop({ default: '' })
    public to!: string;

    @Prop({ default: '' })
    public search!: string;

    public tableHeaders = [{
        text: this.$t('BILLING.TABLE.NAME'),
        value: 'company.name',
        sortable: true
    }, {
        text: this.$t('BILLING.TABLE.RIDES'),
        value: 'rides.length',
        sortable: true
    }, {
        text: this.$t('BILLING.TABLE.CASH_EARNINGS'),
        value: 'billingDetails.cashEarnings',
        sortable: false
    }, {
        text: this.$t('BILLING.TABLE.PAYTODAY_EARNINGS'),
        value: 'billingDetails.paytodayEarnings',
        sortable: false
    }, {
        text: this.$t('BILLING.TABLE.PAYTODAY_FEE'),
        value: 'billingDetails.paytodayFee',
        sortable: false
    }, {
        text: this.$t('BILLING.TABLE.SHARE'),
        value: 'billingDetails.share',
        sortable: false
    }, {
        text: this.$t('BILLING.TABLE.EARNINGS'),
        value: 'billingDetails.earnings',
        sortable: false,
        align: 'center'
    }, {
        text: this.$t('BILLING.TABLE.OPTIONS'),
        value: 'options',
        sortable: false,
        align: 'right'
    }];

    public rowSelected(company: Company) {
        this.$emit('rowSelected', company);
    }

    public async generatePDF(row: Billing) {
        const response = await billingRepository.generatePDF({
            company: row.company.id,
            from: this.from,
            to: this.to
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${row.company.name}_${this.from}-${this.to}.pdf`);
        document.body.appendChild(link);
        link.click();
    }
}

