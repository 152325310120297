var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"billing-table"},[_c('v-card',{staticClass:"box-standard-shadow table-container py-1"},[_c('v-data-table',{staticClass:"row-pointer",attrs:{"items":_vm.billings,"search":_vm.search,"headers":_vm.tableHeaders,"items-per-page":10},on:{"click:row":_vm.rowSelected},scopedSlots:_vm._u([{key:"item.billingDetails.earnings",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.billingDetails.earnings))])]}},{key:"item.options",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.generatePDF(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-file-pdf")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('BILLING.DOWNLOAD_PDF')))])])],1)]}},{key:"item.billingDetails.share",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold",class:item.billingDetails.share > 0 ? 'success--text' : 'error--text'},[_vm._v(" "+_vm._s(item.billingDetails.share)+" ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }